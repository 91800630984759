<template>
  <user-logs
    :id="id"
    :mobile="mobile"
  />
</template>
<script>
import UserLogs from '@/views/dashboard/UserLogs'
import { SimpleEventBus } from '@simplifyd-systems/lib.client.core'

export default {
  name: 'SearchUser',
  components: {
    'user-logs': UserLogs,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    mobile: {
      type: String,
      required: false,
    },
  },
  created () {
    if (!this.mobile) return
    SimpleEventBus.$emit('updateHeader', `Logs for ${this.mobile}`)
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.bg-white {
  background: white;
}
</style>
