<template>
  <v-container>
    <s-card class="pa-0 overflow-hidden">
      <loader
        v-if="isLoading"
        message="Loading ...."
      />
      <section v-else>
        <v-row
          class="customers__table__header table__header pl-10 pr-10 pt-2 ma-0"
          no-gutters
        >
          <v-col cols="2">
            <s-text weight="medium" color="gray" size="sm">
              Event Type
            </s-text>
          </v-col>
          <v-col cols="5" class="pl-1">
            <s-text weight="medium" color="gray" size="sm">
              Message
            </s-text>
          </v-col>
          <v-col cols="2" class="pl-3">
            <s-text weight="medium" color="gray" size="sm">
              Telco
            </s-text>
          </v-col>
          <v-col cols="3" class="pl-4">
            <s-text weight="medium" color="gray" size="sm">
              IPs (Public/Private)
            </s-text>
          </v-col>
        </v-row>

        <section
          v-for="(log, index) in logs"
          :key="index"
          class="mt-3"
        >
          <v-row class="pl-10 pr-10 pt-3 pb-4 bottom-thin-border">
            <v-col cols="2" class="d-flex align-center">
              <s-text weight="normal" size="md">{{ log.type }}</s-text>
            </v-col>
            <v-col cols="5" class="d-flex align-center">
              <truncate-text
                :text="log.msg"
              />
            </v-col>
            <v-col cols="2" class="d-flex align-center">
              <s-text weight="medium" size="md" class="wrap-text">{{ log.metadata.telco }}</s-text>
            </v-col>
            <v-col cols="3" class="d-flex align-center">
              <s-text color="dark" weight="normal" size="md">
                {{ log.metadata.public_ip }} / {{ log.metadata.private_ip }}
              </s-text>
            </v-col>
          </v-row>
        </section>
      </section>
    </s-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import TruncateText from '@/components/cards/TruncateText'
export default {
  name: 'UserLogs',
  components: {
    loader: Loader,
    'truncate-text': TruncateText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    mobile: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      logs: 'analytic/userLogs',
    }),
  },
  data () {
    return {
      isLoading: false,
    }
  },
  methods: {
    getCurrentAppLogs () {
      this.isLoading = true
      this.$store
        .dispatch('analytic/getUserLogs', this.id)
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  watch: {
    logs: {
      handler () {
        if (this.logs) return
        this.getCurrentAppLogs()
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
